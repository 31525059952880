import Container from '@mui/material/Container'
import { storyblokEditable } from '@storyblok/react'
import StoryblokComponent from '@components/Storyblok/StoryblokComponent'
import cn from '@utils/helpers/cn'

const MuiContainer = ({ blok }) => {
  return (
    <Container
      maxWidth={blok.maxWidth}
      className={cn('!px-2 lg:!px-4', blok.class)}
      {...storyblokEditable(blok)}
    >
      {blok.blocks
        ?.filter(
          (b) =>
            (b.hasOwnProperty('showBlock') && b.showBlock) ||
            !b.hasOwnProperty('showBlock'),
        )
        .map((nestedBlok, index) => (
          <StoryblokComponent
            index={index}
            blok={nestedBlok}
            key={nestedBlok._uid}
          />
        ))}
    </Container>
  )
}

export default MuiContainer
